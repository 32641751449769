<div class="container">

  <div class="row pt-3 pb-3">
    <div class="col-md-6">
      <h3>Auto Trip Assignments ({{trips.length}})</h3>
      Requested Trips ({{requestedTrips}}) | Existing Trips ({{existingTrips.length}})
    </div>
    <div class="col-md-3">
      <div class="checkbox float-right">
        <label><input type="checkbox" (change) ="skipTomorrow()" [checked]="isSkipTomorrow"> Skip Tomorrow Trips </label>
        <label><input type="checkbox" (change) ="skipThirdDayWav()" [checked]="isSkipThirdDayWav"> Skip 3rd Day Wav </label>

      </div>
    </div>
    <div class="col-md-3">

      <div class="float-right">

        <div class="form-group">
          <select class="form-control" [(ngModel)]="price">
            <option value="">Select Price</option>
            <option value="50"> > $50</option>
            <option value="40"> > $40</option>
            <option value="30"> > $30</option>
            <option value="25"> > $25</option>
            <option value="20"> > $20</option>
            <option value="15"> > $15</option>
            <option value="10"> > $10</option>
          </select>
        </div>
        <button type="button" class="btn btn-outline-success mr-2" (click)="getTrips()">Refresh</button>
        <button type="button" class="btn btn-outline-primary" (click)="confirmTrips()">Proceed</button>

      </div>
    </div>


  </div>



  <table class="table">
    <thead>
      <tr>
        <th>TripID</th>
        <th>PU Address (Approximate)</th>
        <th>DO Address (Approximate)</th>
        <th style="background-color: rgba(0, 0, 0, 0.05);">Date/Time</th>
        <th style="background-color: rgba(0, 0, 0, 0.05);">Price</th>
        <th style="background-color: rgba(0, 0, 0, 0.05);">Service</th>
        <th>Miles</th>
        <th>Match Time</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let trip of trips; index as i">
        <td>{{trip.boltTripId}}</td>
        <td>{{trip.puAddress}}, {{trip.puCity}}</td>
        <td>{{trip.doAddress}}, {{trip.doCity}} </td>
        <td>{{trip.dueTime}}</td>
        <td>${{trip.payAmount}}</td>
        <td>{{trip.serviceLevel}}</td>
        <td>{{trip.tripMiles}}</td>
        <td>{{trip.matchTime}}</td>

      </tr>
    </tbody>
  </table>
</div>